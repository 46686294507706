.asscrollbar{
  width: 0px;
}
body {
  background: #fef0ec;
  font-family: 'PP Neue Montreal', sans-serif;
  font-weight: 200;
  color: #072AC8;
  margin: 0; /* Reset default margin for the body */
  padding: 0; /* Reset default padding for the body */
}
/* Add styles for hidden header */
.header.hidden {
  opacity: 0;
  /* Add any other styles you want to apply when the header is hidden */
}
.header-wrapper {
    background: #fef0ec;
  
    display: flex; 
}

/* Andrew Pruvost Header START */
.header{
    font-weight: 475;
    position: fixed;
    left: 5vh;
    top: 5vh;
    font-size: max(28px, 3vw);
    z-index: 10;
    /* text-shadow: 0 1px 1px #000; */
    transition: opacity 0.6s ease;
    font-family: 'PP Editorial New', sans-serif;
}

.header a{
    text-decoration: none;
    color: #072AC8;
}


.aboutTitle {
  margin-top: 10vh;
}

/* Andrew Pruvost Header END */

/* SOFTWARE ENGINEER Header START */
.header h1{
 
  font-family: 'PP Neue Montreal', sans-serif;
  font-weight: 300;
  color:#072AC8;
  font-size: max(24px, 1vw); 
  opacity: 0.5;
  line-height: 2.2em;
  
  
}
/* SOFTWARE ENGINEER Header END */

/* Buger Menu Start */
#menu {
    z-index: 2;

  }
  
  #menu-bar {
    width: 30px;
    height: 5vh;
    margin: 63px 10vw 0px 0px;
    cursor: pointer;
  }
  
  .bar {
    height: 4px;
    width: 100%;
    background-color: #072AC8;
    display: block;
    border-radius: 5px;
    transition: 0.3s ease;
  }
  
  #bar1 {
    transform: translateY(-4px);
  }
  
  #bar3 {
    transform: translateY(4px);
  }
  
  .nav {
    transition: 0.3s ease;
    display: none;
  }
  
  .nav ul {
    padding: 0 0px;
  }
  
  .nav li {
    list-style: none;
    padding: 12px 0px;
    margin-right: 20px;
  }
  
  .nav li a {
    color: #072AC8;
    font-size: 1.2em;
    
    text-decoration: none;
    margin-right: 0px;
  }
  
  .nav li a:hover {
   
    opacity: 0.5;
  }
  
  .menu-bg, #menu {
    top: 0;
    right: 0;
    position: fixed;
  }
  
  .menu-bg {
    z-index: 1;
    width: 0;
    height: 0;
    margin: 0px 0px 0px 0px;
    background: radial-gradient(circle, #FEF0EC, #fcd6cc);
    border-radius: 50%;
    transition: 0.5s ease;
  }
  
  .change {
    display: block;
  }
  
  .change .bar {
    background-color: #072AC8;
  }
  
  .change #bar1 {
    transform: translateY(3px) rotateZ(-45deg);
  }
  
  .change #bar2 {
    opacity: 0;
  }
  
  .change #bar3 {
    transform: translateY(-5px) rotateZ(45deg);
  }
  
  .change-bg {
    width: 650px;
    height: 650px;
    transform: translate(55%,-25%);
  }
/* Buger Menu End */

#container{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    

    }
    .scroll-wrap {
      z-index: 1;
      
 /* Add this line to vertically center content */
 
    }
    
.content{
    
    
    
    
    padding-top:28vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2vw;
    padding-bottom: 30vh;
    align-items: center;
    flex: 1;
    justify-content: center;

    /* width: auto; */
}

/* image */
.image{
  display: flex;
  
}

.image img {
 
  width: 40vw;
  height: 25vw;
  
  
  
}
#portrait {
  
  width:30vw;
   /* Set a relative width based on viewport width (vw) */
  height: auto; /* Allow the height to adjust automatically based on the width */
  max-width: 100%; /* Ensure the image does not exceed its original size */
  max-height: 100%; /* Ensure the image does not exceed its original size */
  


}


#sorbonne1 {
  width: 30vw;
  height: 30vw;
}
#agathe{
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  width: 46vw;
  height: 30vw;
}
#phone{
  width: 16vw;
  height: 35vw;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}
#bracket{
  width: 16vw;
  height: 32vw;
}

/* item */
.item{
    font-family: 'PP Neue Montreal', sans-serif;
    text-decoration: none;
    color: #133df6;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center;

}
.item a{
    text-decoration: none;
    color: #133df6;
    opacity: 0.00002;
  
}
.item h2{
   font-size: max(21px, 2vw);
    margin-top: 20px;
    font-weight: 300;
    color: #133df6;
    flex-wrap: wrap;
    opacity: .7;
    max-width: 60vw;
}


.item p{
    margin-top: 20px;
    font-weight: 400;
    color: #133df6;
    opacity: 0.5;
}

.item img{
    width: 22vw;
    height: auto;
    
    object-fit: cover;
}



/* single */
.single{
  width: 100vw;
}
.single h1{
    margin-bottom: 1em;
    font-size: 2.125em;
    font-weight: 700;
    
}
.single h2{
  font-weight: 700;
  font-size: 1.875em;
  max-width: 68vw;
  border-bottom: 1.5px solid #072AC8;
  padding-bottom: 0.6em;
  
}
.single h2 span {
  text-decoration: none !important; /* Use !important to increase specificity */
  font-weight: normal !important; /* Use !important to increase specificity */
  border-bottom: none !important; /* Use !important to increase specificity */
  font-size: 0.75em;
}

.single h3{
  margin-top: 1.5em;
  font-size: 1.5em;
  line-height: 1.5em;
}
figure{
    margin: 0 0 3em 0;
    display: flex;
    gap: 50px;
}
figure+figure{
    flex-direction: row-reverse;
}
figure img{
    float: left;
    margin-right: 30px;
}
.single__content{
    max-width: 100vw;
    margin: 10vh 5vw;
    
 
    
}
.single__content p{
    margin: 1em 0;
    line-height: 1.7;
    font-size: 1.25em;
    max-width: 80vw;
    text-align: justify;
   
}
.single__content a{
    color: #072ac8;
    font-size: 1.1em;
}
.single__top{
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
}
/* stack item  */

.stack {
  margin-top: 1em;
}

.stack h2 {
  font-size: 1.875em;
  color: #072AC8;
  max-width: 60vw;
}
.stack h3 {
  font-size: 1.5em;
  color: #072AC8;
  margin-bottom: 3vh;
}

.left-stack,
.right-stack {
  display: inline-flex;
  width: 49%; /* Adjust the width as needed */
}
.stack ul {
  list-style:square;
  padding: 12px;

  list-style-type: square;
}

.stack li {
  font-size: 1.25em;
  color: #133df6;
  margin-bottom: 8px;
  line-height: 1.5em;
  
}

/* FreeLance item  */
.freel {
  margin-top: 1em;
}

.freel a{
  margin-top: 2vh;
}
.freel h2 {
  font-size: 1.875em;
  color: #072AC8;
  max-width: 60vw;
}
/* Experiment with font styles for better hierarchy */
.freel h3 {
  font-size: 1.5em;
  font-weight: 600;
  color: #072AC8;
  margin-bottom: 1.2vh;
  margin-top: 1.5vh;
}
.left-freel,
.right-freel {
  display: inline-flex;
  width: 49%; /* Adjust the width as needed */
}
/* Adjust spacing for better readability */
.freel ul {
  
  padding: 8px;
  
  width: 70vw;
  margin-top: 1.5vh; /* Add margin for separation */
}

.freel li {
  font-size: 1.25em;
  color: #072AC8;
  margin-bottom: 8px;
  line-height: 1.5em;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  
  
  width: 100%;
  border-top: 1px solid #ccc;
  padding-top: 20px;
  padding-bottom: 50px;
  display: flex;
  font-size: 0.8em;
  padding-left: 40px;
  
}

.footer p + p {
  margin-left: auto;
  text-align-last: left;
  padding-right: 10vw;
}


.curtain{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: #fde0d8;
    transform: translate(0,100%)
}

[data-barba-namespace]{
    position: absolute;
    top: 0;
}

canvas{
  display: block;
}

/* Scroll down animation */


.field {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 300px;
    margin-bottom: 60px;
}






.scroll {
	width: 60px;
	height: 60px;
	border: 2px solid #072AC8;
	border-radius: 50%;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
}

.scroll::before {
	content: '';
	position: absolute;
	top: 15px;
	left: 18px;
	width: 18px;
	height: 18px;
	border-left: 2px solid #072AC8;
	border-bottom: 2px solid #072AC8;
	transform: rotate(-45deg);
}

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}



@keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

@-webkit-keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}
* {
	
	margin: 0;
	padding: 0;
}

.containers {
	width: 100%;
	height: 100%;
	opacity: 0;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

/* Container for the hero section */
.hero-container {
  display: flex;
  flex-flow: wrap;
  position: absolute;
  padding-top: 16vh;
  left: 50%;
  font-size:max(30px, 2.18vw) ;
  z-index: 2; /* Place the hero container on top of other content */
  font-weight: 700;
  font-family: 'PP Editorial New', sans-serif;
}

/* Add this style to position "WEB DESIGNER" to the left */
.hero-title-left {
  text-align: center;
  left: -80%;
}

/* Adjust the existing styles */
.hero-title-change {
  display: inline-flex;
  position: relative;
  text-align: center; /* Align the words in the center */
}

/* Animation for each word */
.hero-title-change span {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
  font-family: 'PP Editorial New', sans-serif;
}

.hero-title-left .word-1 {
  opacity: 1;
  transform: translate(0%, -0%);
}

.word-2 {
  transform: translate(-20%, -0%);
}
.word-3 {
  transform: translate(-20%, -0%);
}
.word-4 {
  transform: translate(-18%, -0%);
}
.word-5 {
  transform: translate(-32%, -0%);
}
.word-6 {
  transform: translate(-26%, -0%);
}
.word-7 {
  transform: translate(-20%, -0%);
}

/* show more button */
/* Set the initial state of the content to be hidden */
.show-more-content {
  max-height: none;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

/* Style the "Show More" button */
.show-more-btn {
  color: #072AC8;
  cursor: pointer;
  margin-top: 10px;
  text-decoration: underline;
}

/* Add this style for the visible state */
.show-more-content.visible {
  max-height: 10000vh; /* Adjust the max height as needed */
}
/* FONTS */
/* PP Neue Montreal Pairing */
@font-face {
  font-family: 'PP Neue Montreal Book';
  src: url('../_site/assets/fonts/PP_Neue_Montreal/OTF/PPNeueMontreal-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PP Editorial New';
  src: url('../_site/assets/fonts/PPEditorialNew/PPEditorialNew-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}



.education-section {
  display: flex;
  align-items:normal; /* Align items vertically in the flex container */
  margin-bottom: 2em; /* Adjust the margin as needed */
  margin-top: 3em;
  
}




.icon-logo {
  width: 40px; /* Adjust the width of your icon as needed */
  height: 40px; /* Adjust the height of your icon as needed */
  margin-right: 2vw;
}
/* social media icons */
.social-icons {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Adjust as needed */
  gap: 4vh;

}
.social-icons-contact {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Adjust as needed */
  gap: 20vw;
  padding-top: 20vh;
  padding-left: 10vw;
  padding-right: 10vw;
  flex-wrap: wrap;
}
.social-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.social-icons-contact a {
  flex-direction: row; /* Change flex direction to row */
  justify-content: flex-start; /* Align icons to the start of the row */
 
}
.social-icons a {
  display: inline-flex;
  margin-right: 2vh;
  margin-left: 2vh; /* Adjust the spacing between icons as needed */
}
.social-icons-contact img {
  max-width: auto; /* Adjust the width of the icons as needed */
  height: 10vh; /* Adjust the height of the icons as needed */
  
}
.social-icons img {
  max-width: auto; /* Adjust the width of the icons as needed */
  height: 5vh; /* Default height, adjust as needed */
  min-height: 32px; /* Minimum height for the icons */
  max-height: 44px; /* Maximum height for the icons */
}
/* Add hover effect to the icons if desired */
.social-icons-contact a:hover img {
  filter: brightness(3.5); /* Adjust the brightness on hover as needed */
}
/* Add hover effect to the icons if desired */
.social-icons a:hover img {
  filter: brightness(3.5); /* Adjust the brightness on hover as needed */
}
.social-icons-contact p {
font-size: 2em;
font-weight: 300;
}
/* Media query for screen width 1040px or below */
@media (max-width: 1040px) {
  .content {
    padding-top:24vh;
  }


  .item img {
    width: 26vw; /* Set width to 24vw */
    height: auto; /* Set height to 24vw */
     /* Adjust opacity as needed */
  }

  .social-icons {

    flex-direction: row; /* Change flex direction to row */
    justify-content: flex-start; /* Align icons to the start of the row */
    padding-top: 10vh;
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .social-icons a {
    margin-right: 0px; /* Adjust spacing between icons */
    margin-bottom: 10px; /* Add margin at the bottom to separate rows */
  }

.social-icons-contact {
  padding-top: 8vh;
}
}

/* Existing styles remain unchanged */

/* Media query for screen width 768px or below */
@media (max-width: 768px) {
  .content {
    padding-top: 20vh;
  }

  .item img {

  }

  .social-icons {
    padding-top: 5vh;
  }

  .social-icons a {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  /* Buger Menu Start */
  #menu {
    z-index: 2;

  }

  #menu-bar {
    width: 30px;
    height: 5vh;
    margin: 63px 10vw 0px 0px;
    cursor: pointer;
  }

  .bar {
    height: 4px;
    width: 100%;
    background-color: #072AC8;
    display: block;
    border-radius: 5px;
    transition: 0.3s ease;
  }

  #bar1 {
    transform: translateY(-4px);
  }

  #bar3 {
    transform: translateY(4px);
  }

  .nav {
    transition: 0.3s ease;
    display: none;
  }

  .nav ul {
    padding: 0 0px;
  }

  .nav li a {
    color: #072ac8;
    margin-right: 0;
    font-size: 0.8em;
    text-decoration: none;
  }
  .nav li {
    margin-right: 10px;
    padding: 4px 0;
    list-style: none;
  }

  .nav li a:hover {
  
    opacity: 0.5;
  }

  .menu-bg, #menu {
    top: 0;
    right: 0;
    position: fixed;
  }

  .menu-bg {
    z-index: 1;
    width: 0;
    height: 0;
    margin: 0px 0px 0px 0px;
    background: radial-gradient(circle, #FEF0EC, #fcd6cc);
    border-radius: 50%;
    transition: 0.5s ease;
  }

  .change {
    display: block;
  }

  .change .bar {
    background-color: #072AC8;
  }

  .change #bar1 {
    transform: translateY(3px) rotateZ(-45deg);
  }

  .change #bar2 {
    opacity: 0;
  }

  .change #bar3 {
    transform: translateY(-5px) rotateZ(45deg);
  }

  .change-bg {
    width: 350px;
    height: 350px;
    transform: translate(55%,-10%);
  }
  /* Buger Menu End */
  .social-icons-contact {
    padding-top: 8vh;
  }
}

/* Media query for screen width 480px or below */
@media (max-width: 576px) {
  .content {
    padding-top: 20vh;
   
  }
  .header a {
    font-size: 18px;
    z-index: 3;
  }
  .header h1 {
    font-size: 16px;
    line-height: 1em;
    z-index: 3;
  }
  .image {
    flex-wrap: wrap;
    display: flex;
    
  }
  .image img {
    width: 70vw; /* Adjust the width for smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  .item img {
    width: 42vw; /* Adjust the width for smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  .single h1 {
    font-size: 1.5em;
  }

  .single h2 {
    font-size: 1.2em;
  }

  .single h3 {
    font-size: 1.1em;
  }
  .single__content p{
    font-size: 0.8em;
    width: 70vw;
    text-align: justify;
  }
  .freel li {
    
    margin-bottom: 8px;
    font-size: 0.8em;
    line-height: 1.5em;
}

canvas{
  display: none;
}

.social-icons {
  padding-top: 6vh;

}
.footer p + p {
  text-align-last: left;
  margin-left: auto;
  padding-right: 24vw;
}
/* Buger Menu Start */
#menu {
  z-index: 2;

}

#menu-bar {
  width: 30px;
  height: 6vh;
  margin: 60px 14vw 0px 0px;
  cursor: pointer;
}

.bar {
  height: 4px;
  width: 100%;
  background-color: #072AC8;
  display: block;
  border-radius: 5px;
  transition: 0.3s ease;
}

#bar1 {
  transform: translateY(-4px);
}

#bar3 {
  transform: translateY(4px);
}

.nav {
  transition: 0.3s ease;
  display: none;
}

.nav ul {
  padding: 0 0px;
}

.nav li a {
  color: #072ac8;
  margin-right: 0;
  font-size: 0.8em;
  text-decoration: none;
}
.nav li {
  margin-right: 10px;
  padding: 4px 0;
  list-style: none;
}

.nav li a:hover {
 
  opacity: 0.5;
}

.menu-bg, #menu {
  top: 0;
  right: 0;
  position: fixed;
}

.menu-bg {
  z-index: 1;
  width: 0;
  height: 0;
  margin: 0px 0px 0px 0px;
  background: radial-gradient(circle, #FEF0EC, #fcd6cc);
  border-radius: 50%;
  transition: 0.5s ease;
}

.change {
  display: block;
}

.change .bar {
  background-color: #072AC8;
}

.change #bar1 {
  transform: translateY(3px) rotateZ(-45deg);
}

.change #bar2 {
  opacity: 0;
}

.change #bar3 {
  transform: translateY(-5px) rotateZ(45deg);
}
.iframe {
 display: none;
}
.change-bg {
  width: 350px;
  height: 350px;
  transform: translate(55%,-10%);
}
.social-icons-contact {
  padding-top: 8vh;
}
.social-icons-contact p{
  text-align: center;
}
/* Buger Menu End */
.description {
  margin-left:10vw;
}
}

